import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = ({ data }) => (
  <Layout data={data}>
    <Seo title="404: Not found" data={data} />
    <div className="section">
      <div className="container-xl">
        <div className="row">
          <div className="col-12 text-center">
            <h1>NOT FOUND</h1>
            <p>Pagina non trovata</p>
            <Link to="/" className="d-inline-block mt-2 mb-5">
              Torna alla homepage
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
export const query = graphql`
  {
    ...HeaderFragment
    ...SeoFragment
  }
`;
